html {
  scrollbar-width: thin;
  scrollbar-color: #b58900 transparent;
}

body {
  background-color: var(--bg);
  color: var(--text);
}

body.light-mode {
  --primary: #b58900;
  --secondary: #d3a000;
  --bg: #ffffff;
  --text: #000000;
  --accent: #f9fafb;
}

body.dark-mode {
  --primary: #d3a000;
  --secondary: #b58900;
  --bg: #282c35;
  --text: #ffffff;
  --accent: #363c48;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 5px;
}

::selection {
  background: var(--secondary);
  color: var(--text);
}

blockquote {
  color: var(--text);
}

.btn {
  border: 1px solid var(--primary);
  color: var(--primary);
  background: var(--bg);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.btn:hover {
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

.card {
  background: var(--accent);
  padding: 2rem;
  border-radius: 1rem;
  margin: 3rem 0 2rem;
  position: relative;
  z-index: 5;
}

.card::after {
  content: '';
  width: calc(100% + 1rem);
  height: calc(100% + 2rem);
  background: var(--primary);
  border-radius: 1rem;
  position: absolute;
  top: -2rem;
  right: -1rem;
  z-index: -1;
  transition: all 1s ease-in-out;
  clip-path: circle(5rem at calc(100% - 5rem) 5rem);
}

.card-filled::after {
  animation: fill 1s forwards;
}

@keyframes fill {
  50% {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(5rem at center);
  }

  to {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(100% at center);
  }
}
